<template>
  <div>
    <div class="golstats-data-container">
      <div class="golstats-data-container__custom-header" :style="headerStyles">
        <span>Datos Golstats</span>
        <div class="golstats-data-container__custom-header__select-container">
          <BSelect placeholder="Selecciona uno" :value="tournamentSelected" expanded @input="setTournamentSelected">
            <option v-for="tournament in listTournaments" :key="tournament.id" :value="tournament">
              {{ tournament.name }}
            </option>
          </BSelect>
        </div>
      </div>
      <div class="golstats-data-container__view">
        <div class="golstats-data-new">
          <BCarousel
            :key="updateC"
            class="myCarousel-PC"
            :interval="5000"
            autoplay
            :indicator-inside="false"
            :pause-info="true"
            :progress="true"
            progress-type="is-progress1"
          >
            <template v-for="(newItem, k) in newsPrint">
              <template v-if="newsPrint[k + 1]">
                <BCarouselItem :key="k + 1000">
                  <div class="golstats-data-new__item">
                    <div class="item-new-golstats">
                      <div class="item-new-golstats__container-jersey" :class="{ 'is-player': newItem.isType === 1 }">
                        <img
                          :src="
                            newsPrint[k].isType == 1
                              ? `${playersImagesUrl}${newsPrint[k].playerId}.png`
                              : newsPrint[k].logo
                          "
                          :style="newsPrint[k].isType == 1 ? `border-radius: 5px;` : ``"
                        />
                        <div
                          v-if="newsPrint[k].isType == 1"
                          class="item-new-golstats__container-jersey__country"
                          :style="{
                            'background-image': `url(${newsPrint[k].logo})`,
                          }"
                        ></div>
                      </div>
                      <div class="item-new-golstats__container-new">
                        <div class="item-new-golstats__container-new__title">
                          {{ newsPrint[k].title }}
                        </div>
                        <div
                          v-html="getFormattedBullet(newsPrint[k].description)"
                          class="item-new-golstats__container-new__nota"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <template v-if="newsPrint[k + 1]">
                    <div class="golstats-data-new__item">
                      <div class="item-new-golstats">
                        <div
                          class="item-new-golstats__container-jersey"
                          :class="{ 'is-player': newsPrint[k + 1].isType === 1 }"
                        >
                          <img
                            :src="
                              newsPrint[k + 1].isType == 1
                                ? `${playersImagesUrl}${newsPrint[k + 1].playerId}.png`
                                : newsPrint[k + 1].logo
                            "
                            :style="newsPrint[k + 1].isType == 1 ? `border-radius: 5px;` : ``"
                          />
                          <div
                            v-if="newsPrint[k + 1].isType == 1"
                            class="item-new-golstats__container-jersey__country"
                            :style="{
                              'background-image': `url(${newsPrint[k + 1].logo})`,
                            }"
                          ></div>
                        </div>
                        <div class="item-new-golstats__container-new">
                          <div class="item-new-golstats__container-new__title">
                            {{ newsPrint[k + 1].title }}
                          </div>
                          <div
                            class="item-new-golstats__container-new__nota"
                            v-html="getFormattedBullet(newsPrint[k + 1].description)"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </template>
                </BCarouselItem>
              </template>
            </template>
          </BCarousel>
          <BCarousel
            :key="updateC + 1"
            class="myCarousel-Movil"
            autoplay
            :interval="5000"
            :indicator-inside="false"
            :pause-info="true"
            :progress="true"
            progress-type="is-progress1"
          >
            <template v-for="(newItem, k) in newsPrint">
              <BCarouselItem :key="k + 11 + 'm'">
                <div class="golstats-data-new__item">
                  <div class="item-new-golstats__title-movil">
                    {{ newItem.title }}
                  </div>

                  <div class="item-new-golstats">
                    <div class="item-new-golstats__container-jersey" :class="{ 'is-player': newItem.isType === 1 }">
                      <img
                        :src="newItem.isType == 1 ? `${playersImagesUrl}${newItem.playerId}.png` : newItem.logo"
                        :style="newItem.isType === 1 ? `border-radius: 5px;` : ``"
                      />
                      <div
                        v-if="newItem.isType == 1"
                        class="item-new-golstats__container-jersey__country"
                        :style="{ 'background-image': `url(${newItem.logo})` }"
                      ></div>
                    </div>
                    <div class="item-new-golstats__container-new">
                      <div class="item-new-golstats__container-new__title">{{ newItem.title }}</div>
                      <div
                        class="item-new-golstats__container-new__nota"
                        v-html="getFormattedBullet(newItem.description)"
                      ></div>
                    </div>
                  </div>
                </div>
              </BCarouselItem>
            </template>
          </BCarousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
import { BSelect } from 'buefy/dist/esm/select';
export default {
  name: 'GolStatsData',
  components: { BSelect, BCarousel, BCarouselItem },
  props: {
    custom: {
      type: Object,
      default: null,
    },
    awaitForTournaments: {
      type: Boolean,
      default: false,
    },
    tournaments: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headerStyles() {
      return {
        color: this.custom.color,
        background: this.custom.headerBackground,
        borderColor: this.custom.headerColor,
      };
    },
  },
  async created() {
    try {
      if (!this.awaitForTournaments) {
        await this.fillListTournaments();
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  data() {
    return {
      updateC: 0,
      arrayColors: {
        //liga mx
        1: { color: '#1B2956', shadow: '#FFFFFF' },
        2: { color: '#1B2956', shadow: '#FFFFFF' },
        4: { color: '#FFFFFF', shadow: 'transparent' },
        5: { color: '#FFFFFF', shadow: 'transparent' },
        7: { color: '#1B2956', shadow: '#FFFFFF' },
        8: { color: '#FFFFFF', shadow: 'transparent' },
        9: { color: '#1B2956', shadow: '#FFFFFF' },
        10: { color: '#1B2956', shadow: '#FFFFFF' },
        11: { color: '#FFC68F', shadow: 'transparent' },
        12: { color: '#FFFFFF', shadow: 'transparent' },
        13: { color: '#FFFFFF', shadow: 'transparent' },
        14: { color: '#1B2956', shadow: '#FFFFFF' },
        16: { color: '#FFFFFF', shadow: 'transparent' },
        17: { color: '#FFFFFF', shadow: 'transparent' },
        18: { color: '#FFFFFF', shadow: 'transparent' },
        173: { color: '#FFFFFF', shadow: 'transparent' },
        205: { color: '#147F55', shadow: '#FFFFFF' },
        3014: { color: '#FFFFFF', shadow: 'transparent' },
        //expan
        482: { color: '#1D1D1D', shadow: '#FFFFFF' }, //alebrijes
        3: { color: '#FFFFFF', shadow: 'transparent' }, //atalante
        3161: { color: '#1D1D1D', shadow: '#FFFFFF' }, //cancun
        908: { color: '#1B2956', shadow: '#FFFFFF' }, //cimarrones
        3162: { color: '#1B2956', shadow: '#FFFFFF' }, //morellia
        557: { color: '#1B2956', shadow: '#FFFFFF' }, //celaya
        554: { color: '#FFFFFF', shadow: 'transparent' }, //correaceminos
        195: { color: '#FFFFFF', shadow: 'transparent' }, //dorados
        757: { color: '#FFFFFF', shadow: 'transparent' }, //mineros
        3166: { color: '#FFC68F', shadow: 'transparent' }, //tabasco
        3328: { color: '#1B2956', shadow: '#FFFFFF' }, //rayados
        3163: { color: '#1B2956', shadow: '#FFFFFF' }, //tapatio
        3164: { color: '#FFFFFF', shadow: 'transparent' }, //tepatitaln
        3165: { color: '#FFFFFF', shadow: 'transparent' }, //tlaxcala
        1603: { color: '#1D1D1D', shadow: '#FFFFFF' }, //tampico
        235: { color: '#1D1D1D', shadow: '#FFFFFF' }, //udg
        592: { color: '#1B2956', shadow: '#FFFFFF' }, //tapachula
        //femenil
        2734: { color: '#1B2956', shadow: '#FFFFFF' }, //america
        2735: { color: '#FFFFFF', shadow: 'transparent' }, //atlas
        2978: { color: '#FFFFFF', shadow: 'transparent' }, //san luis
        2749: { color: '#FFFFFF', shadow: 'transparent' }, //xolos
        2737: { color: '#FFFFFF', shadow: 'transparent' }, //cruz azul
        3018: { color: '#FFFFFF', shadow: 'transparent' }, //juarez
        2736: { color: '#1B2956', shadow: '#FFFFFF' }, //chivas
        2738: { color: '#FFFFFF', shadow: 'transparent' }, //leon
        2740: { color: '#FFFFFF', shadow: 'transparent' }, //mazatlan
        2741: { color: '#1B2956', shadow: '#FFFFFF' }, //monterrey
        2742: { color: '#147F55', shadow: '#FFFFFF' }, //necaxa
        2743: { color: '#1B2956', shadow: '#FFFFFF' }, //pachuca
        2744: { color: '#1B2956', shadow: '#FFFFFF' }, //puebla
        2746: { color: '#FFFFFF', shadow: 'transparent' }, //queretaro
        2747: { color: '#1B2956', shadow: '#FFFFFF' }, //santos
        2748: { color: '#FFFFFF', shadow: 'transparent' }, //tigres
        2750: { color: '#FFFFFF', shadow: 'transparent' }, //toluca
        2745: { color: '#FFC68F', shadow: 'transparent' }, //pumas
      },
      url: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      // url: 'https://25d2d6f5ad.execute-api.us-west-2.amazonaws.com/qa/tournaments',
      urlData: 'https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/seasons',
      listTournaments: [],
      listSeasonsByTournament: [],
      listSeasons: {},
      groupedSeasons: null,
      tournamentSelected: {},
      seasonSelected: {},
      newsData: [],
      newsPrint: [],
      playersImagesUrl: 'https://az755631.vo.msecnd.net/players-profile/',
    };
  },
  watch: {
    newsData() {
      this.shuffle(this.newsData);
      var idsShow = [];
      if (localStorage.getItem('newsDataStorage')) {
        idsShow = localStorage.getItem('newsDataStorage');
        var temp_idsShow = idsShow.split(',');
        for (var a in temp_idsShow) {
          temp_idsShow[a] = parseInt(temp_idsShow[a], 10);
        }
        idsShow = temp_idsShow;
      } else {
        idsShow = [];
        localStorage.setItem('newsDataStorage', idsShow);
      }
      if (this.newsData.length == idsShow.length) {
        idsShow = [];
      }
      var idShowTemp = [];
      var contPrint = 0;
      for (let index = 0; index < this.newsData.length; index++) {
        if (!idsShow.includes(this.newsData[index].order)) {
          this.newsPrint.push(this.newsData[index]);
          contPrint += 1;
          if (contPrint <= 2) {
            idShowTemp.push(this.newsData[index].order);
            idsShow.push(this.newsData[index].order);
          }
        }
      }
      for (let index = 0; index < this.newsData.length; index++) {
        if (idsShow.includes(this.newsData[index].order)) {
          if (!idShowTemp.includes(this.newsData[index].order)) {
            this.newsPrint.push(this.newsData[index]);
          }
        }
      }
      localStorage.setItem('newsDataStorage', idsShow);
    },
    tournaments: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setTournamentsData(newValue);
        }
      },
    },
  },
  methods: {
    async fillData() {
      try {
        const { data: news } = await axios
          .get(this.urlData + '/' + this.seasonSelected.id + '/news')
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.fillData();
            }
            return e;
          });
        if (news) {
          this.newsPrint = [];
          this.newsData = [];
          for (let index = 0; index < news.news.length; index++) {
            const newItem = {
              isType: news.news[index].is_player,
              title: news.news[index].title,
              description: news.news[index].description,
              jersey: news.news[index].jersey,
              logo: news.news[index].team_logo,
              team: news.news[index].team_id,
              order: news.news[index].order,
              playerId: news.news[index].player_id,
            };
            newItem.logo = newItem.logo.replace('teams-80', 'teams-150');
            this.newsData.push(newItem);
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getFormattedBullet(bullet) {
      let formattedBullet = bullet;
      const matches = bullet.match(/\*/g);
      if (matches && matches.length) {
        if (matches.length % 2 === 0) {
          for (let i = 1; i <= matches.length; i++) {
            if (i % 2 === 0) {
              formattedBullet = formattedBullet.replace('*', '</span>');
            } else {
              formattedBullet = formattedBullet.replace(
                '*',
                '<span style="font-family: Avenir-Pro-Bold; text-decoration: underline;">',
              );
            }
          }
        } else {
          formattedBullet = bullet.replaceAll('*', '');
        }
      }
      return `${formattedBullet}`;
    },
    shuffle(a) {
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    },
    getColor(team) {
      if (team) {
        var color = this.arrayColors[team].color;
        return color;
      }
    },
    getShadow(team) {
      if (team) {
        var color =
          '2px 0 0 ' +
          this.arrayColors[team].shadow +
          ', -2px 0 0 ' +
          this.arrayColors[team].shadow +
          ', 0 2px 0 ' +
          this.arrayColors[team].shadow +
          ', 0 -2px 0 ' +
          this.arrayColors[team].shadow +
          ', 1px 1px ' +
          this.arrayColors[team].shadow +
          ', -1px -1px 0 ' +
          this.arrayColors[team].shadow +
          ', 1px -1px 0 ' +
          this.arrayColors[team].shadow +
          ', -1px 1px 0 ' +
          this.arrayColors[team].shadow +
          '; -webkit-text-shadow: 2px 0 0 ' +
          this.arrayColors[team].shadow +
          ', -2px 0 0 ' +
          this.arrayColors[team].shadow +
          ', 0 2px 0 ' +
          this.arrayColors[team].shadow +
          ', 0 -2px 0 ' +
          this.arrayColors[team].shadow +
          ', 1px 1px ' +
          this.arrayColors[team].shadow +
          ', -1px -1px 0 ' +
          this.arrayColors[team].shadow +
          ', 1px -1px 0 ' +
          this.arrayColors[team].shadow +
          ', -1px 1px 0 ' +
          this.arrayColors[team].shadow +
          '; -ms-text-shadow: 2px 0 0 ' +
          this.arrayColors[team].shadow +
          ', -2px 0 0 ' +
          this.arrayColors[team].shadow +
          ', 0 2px 0 ' +
          this.arrayColors[team].shadow +
          ', 0 -2px 0 ' +
          this.arrayColors[team].shadow +
          ', 1px 1px ' +
          this.arrayColors[team].shadow +
          ', -1px -1px 0 ' +
          this.arrayColors[team].shadow +
          ', 1px -1px 0 ' +
          this.arrayColors[team].shadow +
          ', -1px 1px 0 ' +
          this.arrayColors[team].shadow +
          '; -o-text-shadow: 2px 0 0 ' +
          this.arrayColors[team].shadow +
          ', -2px 0 0 ' +
          this.arrayColors[team].shadow +
          ', 0 2px 0 ' +
          this.arrayColors[team].shadow +
          ', 0 -2px 0 ' +
          this.arrayColors[team].shadow +
          ', 1px 1px ' +
          this.arrayColors[team].shadow +
          ', -1px -1px 0 ' +
          this.arrayColors[team].shadow +
          ', 1px -1px 0 ' +
          this.arrayColors[team].shadow +
          ', -1px 1px 0 ' +
          this.arrayColors[team].shadow +
          ';';
        return color;
      }
    },

    async fillListTournaments() {
      try {
        const { data: tournaments } = await axios
          .get(this.url)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.fillListTournaments();
            }
            return e;
          });
        this.setTournamentsData(tournaments);
      } catch (e) {
        throw new Error(e);
      }
    },
    setTournamentsData(tournaments) {
      this.groupedSeasons = this.getGroupedSeasons(tournaments);
      if (tournaments) {
        for (let index = 0; index < tournaments.length; index++) {
          const tournamentAux = {
            name: tournaments[index].tournament.name,
            id: tournaments[index]._id,
            logo: tournaments[index].tournament.logo,
            showLogos: tournaments[index].showLogos,
          };
          this.listTournaments.push(tournamentAux);
          if (tournaments[index]._id === 1) {
            this.tournamentSelected = tournamentAux;
          }
          for (let indexSeason = 0; indexSeason < tournaments[index].seasons.length; indexSeason++) {
            this.listSeasons[tournaments[index]._id] = {
              name: tournaments[index].seasons[indexSeason].name,
              id: tournaments[index].seasons[indexSeason].id,
              logo: tournaments[index].seasons[indexSeason].logo,
            };
          }
        }
      }
      this.listSeasonsByTournament.push(this.listSeasons[this.tournamentSelected.id]);
      this.seasonSelected = this.listSeasons[this.tournamentSelected.id];
      if (this.tournamentSelected.id) {
        this.$emit('season-selected', this.seasonSelected.id);
        this.$emit('tournament-selected', this.tournamentSelected);
        this.$emit('seasons-selected', this.groupedSeasons[this.seasonSelected.id]);
        this.fillData();
      }
    },
    reateGroupedSeasons(tournamentsAndSeasons) {
      this.groupedSeasons = tournamentsAndSeasons.reduce((groupedSeasons, tournamentAndSeasons) => {
        const regularSeason = tournamentAndSeasons.seasons[0];
        groupedSeasons[regularSeason.id] = [
          { id: regularSeason.id, type: regularSeason.type, name: regularSeason.name },
        ];
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          groupedSeasons[regularSeason.id].push(
            ...regularSeason.related_seasons.map(relatedSeason => ({
              id: relatedSeason.id,
              type: relatedSeason.type,
            })),
          );
        }
        return groupedSeasons;
      }, {});
    },
    async setTournamentSelected(val) {
      localStorage.setItem('newsDataStorage', []);
      this.tournamentSelected = val;
      this.listSeasonsByTournament = [];
      this.listSeasonsByTournament.push(this.listSeasons[this.tournamentSelected.id]);
      this.seasonSelected = this.listSeasons[this.tournamentSelected.id];
      await this.fillData();
      this.updateC += 1;
      this.$emit('season-selected', this.seasonSelected.id);
      this.$emit('tournament-selected', this.tournamentSelected);
      this.$emit('seasons-selected', this.groupedSeasons[this.seasonSelected.id]);
    },
    getGroupedSeasons(tournamentsAndSeasons) {
      return tournamentsAndSeasons.reduce((groupedSeasons, tournamentAndSeasons) => {
        const regularSeason = tournamentAndSeasons.seasons[0];
        groupedSeasons[regularSeason.id] = [
          { id: regularSeason.id, type: regularSeason.type, name: regularSeason.name },
        ];
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          groupedSeasons[regularSeason.id].push(
            ...regularSeason.related_seasons.map(relatedSeason => ({
              id: relatedSeason.id,
              type: relatedSeason.type,
            })),
          );
        }
        return groupedSeasons;
      }, {});
    },
  },
};
</script>
<style>
.golstats-data-new .carousel-indicator {
  display: none !important;
}
.golstats-data-new .carousel-arrow.is-hovered {
  opacity: 1 !important;
}

html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
.golstats-data-new .carousel-arrow .icon.has-icons-right {
  right: 600px !important;
  top: 172px !important;
}
.golstats-data-new .carousel-arrow .icon.has-icons-left {
  left: 600px !important;
  top: 172px !important;
}
@media screen and (max-width: 1266px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 560px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 562px !important;
  }
}
@media screen and (max-width: 1196px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 497px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 497px !important;
  }
}
@media screen and (max-width: 1068px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 447px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 449px !important;
  }
}
@media screen and (max-width: 968px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 399px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 399px !important;
  }
}
@media screen and (max-width: 868px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 349px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 349px !important;
  }
}
@media screen and (max-width: 768px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 299px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 299px !important;
  }
}
@media screen and (max-width: 668px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 249px !important;
    top: 180px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 249px !important;
    top: 180px !important;
  }
}
@media screen and (max-width: 568px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 199px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 199px !important;
  }
}
@media screen and (max-width: 468px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 172px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 172px !important;
  }
}
@media screen and (max-width: 414px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 145px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 145px !important;
  }
}
@media screen and (max-width: 360px) {
  .golstats-data-new .carousel-arrow .icon.has-icons-right {
    right: 105px !important;
  }
  .golstats-data-new .carousel-arrow .icon.has-icons-left {
    left: 105px !important;
  }
}
</style>
<style lang="scss" scoped>
.myCarousel-Movil {
  display: none;
  @media screen and (max-width: 668px) {
    display: inline-block;
    width: 568px;
  }
  @media screen and (max-width: 568px) {
    width: 468px;
  }
  @media screen and (max-width: 468px) {
    width: 414px;
  }
  @media screen and (max-width: 414px) {
    width: 360px;
  }
  @media screen and (max-width: 360px) {
    width: 280px;
  }
}
.myCarousel-PC {
  width: 1268px;
  @media screen and (max-width: 1268px) {
    width: 1196px;
  }
  @media screen and (max-width: 1196px) {
    width: 1068px;
  }
  @media screen and (max-width: 1068px) {
    width: 968px;
  }
  @media screen and (max-width: 968px) {
    width: 868px;
  }
  @media screen and (max-width: 868px) {
    width: 768px;
  }
  @media screen and (max-width: 768px) {
    width: 668px;
  }
  @media screen and (max-width: 668px) {
    display: none;
    width: 312px !important;
  }
}
.item-new-golstats {
  width: 600px;
  height: 160px;
  display: inline-flex;
  @media screen and (max-width: 1266px) {
    width: 568px;
  }
  @media screen and (max-width: 1196px) {
    width: 505px;
  }
  @media screen and (max-width: 1068px) {
    width: 460px;
  }
  @media screen and (max-width: 968px) {
    width: 412px;
  }
  @media screen and (max-width: 868px) {
    width: 362px;
  }
  @media screen and (max-width: 768px) {
    width: 312px;
  }
  @media screen and (max-width: 668px) {
    width: 548px;
    height: 140px;
  }
  @media screen and (max-width: 568px) {
    width: 448px;
  }
  @media screen and (max-width: 468px) {
    width: 394px;
  }
  @media screen and (max-width: 414px) {
    width: 340px;
  }
  @media screen and (max-width: 360px) {
    width: 260px;
  }
  &__title-movil {
    width: 600px;
    height: 30px;
    display: none;
    font-family: 'Circular-Std-Bold';
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.46px;
    text-align: left;
    color: #f00;
    padding-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 22px;
    float: left;
    @media screen and (max-width: 1266px) {
      width: 568px;
    }
    @media screen and (max-width: 1196px) {
      width: 505px;
      font-size: 21px;
    }
    @media screen and (max-width: 1068px) {
      width: 460px;
      font-size: 20px;
    }
    @media screen and (max-width: 968px) {
      width: 412px;
      font-size: 19px;
    }
    @media screen and (max-width: 868px) {
      width: 362px;
      font-size: 18px;
    }
    @media screen and (max-width: 768px) {
      width: 312px;
      font-size: 17px;
    }
    @media screen and (max-width: 668px) {
      width: 548px;
      display: inline-block;
      font-size: 21px;
    }
    @media screen and (max-width: 568px) {
      width: 448px;
      font-size: 20px;
      padding-left: 15px;
    }
    @media screen and (max-width: 468px) {
      width: 394px;
      font-size: 19px;
      padding-left: 12px;
      padding-top: 5px;
    }
    @media screen and (max-width: 414px) {
      width: 340px;
      padding-top: 5px;
    }
    @media screen and (max-width: 360px) {
      width: 260px;
      padding-left: 6px;
      padding-top: 5px;
    }
  }
  &__container-new {
    width: 414px;
    height: 114px;
    margin-left: 19px;
    margin-top: 30px;
    float: left;
    @media screen and (max-width: 1266px) {
      margin-left: 15px;
      width: 430px !important;
    }
    @media screen and (max-width: 1196px) {
      margin-left: 8px;
      margin-top: 34px;
      width: 390px !important;
    }
    @media screen and (max-width: 1068px) {
      margin-top: 37px;
      width: 353px !important;
    }
    @media screen and (max-width: 968px) {
      margin-top: 44px;
      width: 317px !important;
    }
    @media screen and (max-width: 868px) {
      margin-top: 47px;
      width: 278px !important;
    }
    @media screen and (max-width: 768px) {
      margin-top: 50px;
      width: 237px !important;
    }
    @media screen and (max-width: 668px) {
      margin-top: 2px;
      width: 420px !important;
    }
    @media screen and (max-width: 568px) {
      width: 330px !important;
    }
    @media screen and (max-width: 468px) {
      width: 286px !important;
    }
    @media screen and (max-width: 414px) {
      width: 241px !important;
    }
    @media screen and (max-width: 360px) {
      width: 180px !important;
    }
    &__title {
      width: 414px;
      height: 30px;
      font-family: 'Circular-Std-Bold';
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.46px;
      text-align: left;
      color: #f00;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 1266px) {
        width: 430px !important;
      }
      @media screen and (max-width: 1196px) {
        font-size: 21px;
        width: 390px !important;
      }
      @media screen and (max-width: 1068px) {
        font-size: 20px;
        width: 353px !important;
      }
      @media screen and (max-width: 968px) {
        font-size: 19px;
        width: 317px !important;
      }
      @media screen and (max-width: 868px) {
        font-size: 18px;
        width: 278px !important;
      }
      @media screen and (max-width: 768px) {
        font-size: 17px;
        width: 237px !important;
      }
      @media screen and (max-width: 668px) {
        font-size: 21px;
        display: none;
        width: 420px !important;
      }
      @media screen and (max-width: 568px) {
        font-size: 19px;
        width: 330px !important;
      }
      @media screen and (max-width: 468px) {
        font-size: 18px;
        width: 286px !important;
      }
      @media screen and (max-width: 414px) {
        width: 241px !important;
      }
      @media screen and (max-width: 360px) {
        width: 180px !important;
      }
    }
    &__nota {
      width: 414px;
      min-height: 50px;
      font-family: 'Avenir-Medium';
      font-size: 19px;
      height: 95px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      // max-height: 80px;
      line-height: 23px;
      letter-spacing: -0.4px;
      text-align: left;
      color: #132839;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 1266px) {
        width: 430px !important;
        height: 90px;
      }
      @media screen and (max-width: 1196px) {
        font-size: 18px;
        width: 390px !important;
        height: 100px;
      }
      @media screen and (max-width: 1068px) {
        font-size: 17px;
        width: 353px !important;
        height: 95px;
      }
      @media screen and (max-width: 968px) {
        font-size: 16px;
        width: 317px !important;
        line-height: 21px;
        height: 85px;
      }
      @media screen and (max-width: 868px) {
        font-size: 15px;
        line-height: 19px;
        width: 278px !important;
        height: 78px;
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
        width: 237px !important;
      }
      @media screen and (max-width: 668px) {
        font-size: 18px;
        height: 115px;
        line-height: 23px;
        padding-top: 5px;
        max-height: 110px;
        -webkit-line-clamp: 4;
        width: 420px !important;
      }
      @media screen and (max-width: 568px) {
        font-size: 17px;
        padding-top: 8px;
        width: 330px !important;
      }
      @media screen and (max-width: 468px) {
        font-size: 16px;
        padding-top: 12px;
        max-height: 102px;
        width: 286px !important;
      }
      @media screen and (max-width: 414px) {
        padding-top: 14px;
        max-height: 108px;
        line-height: 19px;
        -webkit-line-clamp: 5;
        width: 241px !important;
      }
      @media screen and (max-width: 360px) {
        padding-top: 10px;
        width: 195px !important;
        line-height: 19px;
        -webkit-line-clamp: 5;
        height: 128px;
        font-size: 15px;
      }
      &__decoration {
        font-family: 'Avenir-Pro-Bold';
        text-decoration: underline;
      }
    }
  }
  &__container-jersey {
    width: 114px;
    height: fit-content;
    margin-left: 26px;
    margin-top: 25px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    & > img {
      margin-top: 8px;
      @media screen and (max-width: 1068px) {
        margin-top: 13px;
      }
      @media screen and (max-width: 968px) {
        margin-top: 18px;
      }
      @media screen and (max-width: 868px) {
        margin-top: 24px;
      }
      @media screen and (max-width: 668px) {
        margin-top: 4px;
      }
    }
    @media screen and (max-width: 1266px) {
      margin-left: 14px;
    }
    @media screen and (max-width: 1196px) {
      margin-left: 0px;
    }
    @media screen and (max-width: 1068px) {
    }
    @media screen and (max-width: 968px) {
    }
    @media screen and (max-width: 868px) {
    }
    @media screen and (max-width: 768px) {
    }
    @media screen and (max-width: 668px) {
      margin-left: 7px;
      margin-top: 2px;
    }
    @media screen and (max-width: 568px) {
      margin-left: 0px;
      width: 134px;
    }
    @media screen and (max-width: 468px) {
      width: 144px;
    }
    @media screen and (max-width: 414px) {
      width: 154px;
    }
    @media screen and (max-width: 360px) {
      width: 164px;
    }
    &__jersey {
      width: 61px;
      height: 62px;
      position: absolute;
      // color: white;
      font-size: 30px;
      text-align: center;
      line-height: 62px;
      left: 27px;
      top: 26px;
      font-family: 'Avenir-Demi';
      @media screen and (max-width: 1266px) {
        width: 58px;
        height: 42px;
        left: 26px;
        font-size: 29px;
      }
      @media screen and (max-width: 1196px) {
        width: 58px;
        height: 42px;
        left: 25px;
        font-size: 29px;
      }
      @media screen and (max-width: 1068px) {
        width: 53px;
        height: 42px;
        left: 23px;
      }
      @media screen and (max-width: 968px) {
        width: 47px;
        height: 42px;
        left: 20px;

        font-size: 24px;
      }
      @media screen and (max-width: 868px) {
        width: 41px;
        height: 42px;
        left: 18px;
        font-size: 21px;
      }
      @media screen and (max-width: 768px) {
        width: 36px;
        height: 42px;

        left: 16px;
        font-size: 17px;
      }
      @media screen and (max-width: 668px) {
        width: 60px;
        height: 62px;

        left: 27px;
        font-size: 30px;
      }
      @media screen and (max-width: 568px) {
        width: 59px;
        height: 62px;
        left: 26px;
        font-size: 28px;
      }
      @media screen and (max-width: 468px) {
        width: 54px;
        height: 62px;
        left: 23px;
        font-size: 26px;
      }
      @media screen and (max-width: 414px) {
        width: 50px;
        height: 62px;
        left: 21px;

        font-size: 24px;
      }
      @media screen and (max-width: 360px) {
        width: 39px;
        height: 62px;

        left: 17px;
        font-size: 19px;
      }
    }
    &__country {
      width: 32px;
      height: 32px;
      position: absolute;
      bottom: -4px;
      left: -16px;

      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      @media screen and (max-width: 568px) {
        left: -10px;
      }
    }
  }
}
.golstats-data-new {
  width: 1268px;
  height: 190px;
  margin: 0 auto;
  @media screen and (max-width: 1266px) {
    width: 1196px;
  }
  @media screen and (max-width: 1196px) {
    width: 1068px;
  }
  @media screen and (max-width: 1068px) {
    width: 968px;
  }
  @media screen and (max-width: 968px) {
    width: 868px;
  }
  @media screen and (max-width: 868px) {
    width: 768px;
  }
  @media screen and (max-width: 768px) {
    width: 668px;
    grid-template-columns: repeat(3, 202px);
  }
  @media screen and (max-width: 668px) {
    width: 568px;
  }
  @media screen and (max-width: 568px) {
    width: 468px;
  }
  @media screen and (max-width: 468px) {
    width: 414px;
  }
  @media screen and (max-width: 414px) {
    width: 360px;
  }
  @media screen and (max-width: 360px) {
    width: 280px;
  }
  &__item {
    width: 600px;
    height: 185px;
    float: left;
    margin-left: 20px;
    margin-right: 10px;
    @media screen and (max-width: 1266px) {
      width: 568px;
      margin-left: 17px;
      height: 185px;
    }
    @media screen and (max-width: 1196px) {
      width: 505px;
      margin-left: 16px;
    }
    @media screen and (max-width: 1068px) {
      width: 460px;
      margin-left: 13px;
    }
    @media screen and (max-width: 968px) {
      width: 412px;
      margin-left: 11px;
    }
    @media screen and (max-width: 868px) {
      width: 362px;
    }
    @media screen and (max-width: 768px) {
      width: 312px;
    }
    @media screen and (max-width: 668px) {
      width: 548px;
      // height: 165px;
      margin-top: 10px;
    }
    @media screen and (max-width: 568px) {
      width: 448px;
    }
    @media screen and (max-width: 468px) {
      width: 394px;
    }
    @media screen and (max-width: 414px) {
      width: 340px;
    }
    @media screen and (max-width: 360px) {
      width: 260px;
    }
  }
}

.golstats-data-container {
  margin-top: 30px;
  &__custom-header {
    height: 40px;
    font-family: 'Circular-Std-Medium';
    line-height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 3px 3px 0 0;
    img {
      width: 28px;
      margin-left: 11px;
      margin-top: 4px;
      float: left;
    }
    span {
      float: none;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 24px;
      transform: translate(-50%, -50%);
      @media only screen and (max-width: 768px) {
        font-size: 16px;
        width: 50%;
        left: 10%;
      }
      @media only screen and (max-width: 620px) {
        width: 50%;
        left: 11%;
      }
      @media only screen and (max-width: 578px) {
        left: 12%;
      }
      @media only screen and (max-width: 518px) {
        left: 13%;
      }
      @media only screen and (max-width: 465px) {
        left: 15%;
      }
      @media only screen and (max-width: 420px) {
        left: 16%;
      }
      @media only screen and (max-width: 385px) {
        left: 17%;
      }
      @media only screen and (max-width: 355px) {
        left: 18%;
      }
      @media only screen and (max-width: 340px) {
        left: 19%;
      }
      @media only screen and (max-width: 320px) {
        left: 20%;
        width: 40%;
      }
      @media only screen and (max-width: 300px) {
        left: 21%;
      }
    }
    &__select-container {
      width: 169px;
      height: 32px;
      margin-top: 2px;
      float: right;
      font-weight: 500;
      margin-right: 16px;
      @media only screen and (max-width: 518px) {
        margin-right: 5px;
      }
    }
  }
  &__view {
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.is-player {
  width: 67px;
  height: default;
}
</style>
