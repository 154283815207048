var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"golstats-data-container"},[_c('div',{staticClass:"golstats-data-container__custom-header",style:(_vm.headerStyles)},[_c('span',[_vm._v("Datos Golstats")]),_c('div',{staticClass:"golstats-data-container__custom-header__select-container"},[_c('BSelect',{attrs:{"placeholder":"Selecciona uno","value":_vm.tournamentSelected,"expanded":""},on:{"input":_vm.setTournamentSelected}},_vm._l((_vm.listTournaments),function(tournament){return _c('option',{key:tournament.id,domProps:{"value":tournament}},[_vm._v(" "+_vm._s(tournament.name)+" ")])}),0)],1)]),_c('div',{staticClass:"golstats-data-container__view"},[_c('div',{staticClass:"golstats-data-new"},[_c('BCarousel',{key:_vm.updateC,staticClass:"myCarousel-PC",attrs:{"interval":5000,"autoplay":"","indicator-inside":false,"pause-info":true,"progress":true,"progress-type":"is-progress1"}},[_vm._l((_vm.newsPrint),function(newItem,k){return [(_vm.newsPrint[k + 1])?[_c('BCarouselItem',{key:k + 1000},[_c('div',{staticClass:"golstats-data-new__item"},[_c('div',{staticClass:"item-new-golstats"},[_c('div',{staticClass:"item-new-golstats__container-jersey",class:{ 'is-player': newItem.isType === 1 }},[_c('img',{style:(_vm.newsPrint[k].isType == 1 ? `border-radius: 5px;` : ``),attrs:{"src":_vm.newsPrint[k].isType == 1
                              ? `${_vm.playersImagesUrl}${_vm.newsPrint[k].playerId}.png`
                              : _vm.newsPrint[k].logo}}),(_vm.newsPrint[k].isType == 1)?_c('div',{staticClass:"item-new-golstats__container-jersey__country",style:({
                            'background-image': `url(${_vm.newsPrint[k].logo})`,
                          })}):_vm._e()]),_c('div',{staticClass:"item-new-golstats__container-new"},[_c('div',{staticClass:"item-new-golstats__container-new__title"},[_vm._v(" "+_vm._s(_vm.newsPrint[k].title)+" ")]),_c('div',{staticClass:"item-new-golstats__container-new__nota",domProps:{"innerHTML":_vm._s(_vm.getFormattedBullet(_vm.newsPrint[k].description))}})])])]),(_vm.newsPrint[k + 1])?[_c('div',{staticClass:"golstats-data-new__item"},[_c('div',{staticClass:"item-new-golstats"},[_c('div',{staticClass:"item-new-golstats__container-jersey",class:{ 'is-player': _vm.newsPrint[k + 1].isType === 1 }},[_c('img',{style:(_vm.newsPrint[k + 1].isType == 1 ? `border-radius: 5px;` : ``),attrs:{"src":_vm.newsPrint[k + 1].isType == 1
                                ? `${_vm.playersImagesUrl}${_vm.newsPrint[k + 1].playerId}.png`
                                : _vm.newsPrint[k + 1].logo}}),(_vm.newsPrint[k + 1].isType == 1)?_c('div',{staticClass:"item-new-golstats__container-jersey__country",style:({
                              'background-image': `url(${_vm.newsPrint[k + 1].logo})`,
                            })}):_vm._e()]),_c('div',{staticClass:"item-new-golstats__container-new"},[_c('div',{staticClass:"item-new-golstats__container-new__title"},[_vm._v(" "+_vm._s(_vm.newsPrint[k + 1].title)+" ")]),_c('div',{staticClass:"item-new-golstats__container-new__nota",domProps:{"innerHTML":_vm._s(_vm.getFormattedBullet(_vm.newsPrint[k + 1].description))}})])])])]:_vm._e()],2)]:_vm._e()]})],2),_c('BCarousel',{key:_vm.updateC + 1,staticClass:"myCarousel-Movil",attrs:{"autoplay":"","interval":5000,"indicator-inside":false,"pause-info":true,"progress":true,"progress-type":"is-progress1"}},[_vm._l((_vm.newsPrint),function(newItem,k){return [_c('BCarouselItem',{key:k + 11 + 'm'},[_c('div',{staticClass:"golstats-data-new__item"},[_c('div',{staticClass:"item-new-golstats__title-movil"},[_vm._v(" "+_vm._s(newItem.title)+" ")]),_c('div',{staticClass:"item-new-golstats"},[_c('div',{staticClass:"item-new-golstats__container-jersey",class:{ 'is-player': newItem.isType === 1 }},[_c('img',{style:(newItem.isType === 1 ? `border-radius: 5px;` : ``),attrs:{"src":newItem.isType == 1 ? `${_vm.playersImagesUrl}${newItem.playerId}.png` : newItem.logo}}),(newItem.isType == 1)?_c('div',{staticClass:"item-new-golstats__container-jersey__country",style:({ 'background-image': `url(${newItem.logo})` })}):_vm._e()]),_c('div',{staticClass:"item-new-golstats__container-new"},[_c('div',{staticClass:"item-new-golstats__container-new__title"},[_vm._v(_vm._s(newItem.title))]),_c('div',{staticClass:"item-new-golstats__container-new__nota",domProps:{"innerHTML":_vm._s(_vm.getFormattedBullet(newItem.description))}})])])])])]})],2)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }